import moment from "moment";
import {
  getBase64ImageFromURL,
} from "../../Components/Functionality/Helper";
import axisBarcode from "../../Images/qrcode.jpg";
import { BillingFooter } from "./BillingFooterLayout";

const billingDetails = async (
  location,
  WE_USING_PAYPHONE,
  invoice,
  bankBranch,
  Rate,
  profileData,
  ShowHeader,
  invoiceNo
) => {
  // Check if bull paid by both or one party
  // console.log(location?.data?.data,"location?.data?.dataeight")
  // console.log(builltyData,"builltyData")
  const billingDetails = [
    {
      columns: [
        {
          alignment: "left",
          margin: [0, 0, 0, 0], // Set margin to 0
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 100,
                  h: 25,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: 20, y: ShowHeader ? -19 : -19 },
              text: "INVOICE TO",
              color: "#000",
              fontSize: 11,
              bold: true,
            },
          ],
        },
        {
          margin: [0, 0, 0, 0], // Set margin to 0
          alignment: "right",
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 100,
                  h: 25,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              alignment: "right",
              relativePosition: { x: -16, y: ShowHeader ? -19 : -19 },
              text: "OUR DETAILS",
              color: "#000",
              fontSize: 11,
              bold: true,
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          width: "50%",
          stack: [
            {
              text: `${invoice?.name?.toUpperCase()}`,
              color: "#000",
              fontSize: 11,
              bold: true,
              margin: [0, 5, 0, 0],
            },
            {
              text: `ADDRESS :${invoice?.address.toUpperCase()}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
            },
            {
              text: `GST : ${invoice?.gst?.toUpperCase()}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
            },
          ],
        },
        {
          text: "",
          width: "*",
        },
        {
          alignment: "right",
          width: "50%",
          stack: [
            {
              text: `SERVICE : ${profileData?.service}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
              bold: true,
            },
            {
              text: `PAN NO. : ${profileData?.panNo}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
              bold: true,
            },
            {
              text: `STATE CODE : ${profileData.stateCode}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
              bold: true,
            },
            {
              canvas: [
                {
                  type: "line",
                  x1: 200,
                  y1: 5,
                  x2: 20,
                  y2: 5,
                  lineWidth: 2,
                },
              ],
              margin: [0, 0, 0, 2],
            },
            {
              text: `INVOICE NO : ${
                location?.data?.data?.invoiceNo ||
                location?.data?.data?.bookingInvoiceNo !== null
                  ? location?.data?.data?.invoiceNo
                    ? location?.data?.data?.invoiceNo
                    : location?.data?.data?.bookingInvoiceNo
                  : location?.data?.invoiceNo || invoiceNo
                  ? invoiceNo
                    ? invoiceNo
                    : location?.data?.invoiceNo
                  : "--"
              }`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
              bold: true,
            },
            // {
            //   text: `COMPANY INVOICE NO : ${
            //     location?.data?.data?.companyInvoiceNo
            //       ? location?.data?.data?.invoiceNo
            //         ? location?.data?.data?.invoiceNo.toUpperCase()
            //         : location?.data?.data?.companyInvoiceNo.toUpperCase()
            //       : "--"
            //   }`,
            //   color: "#000",
            //   fontSize: 9,
            //   margin: [0, 5, 0, 0],
            //   alignment: "right",
            //   bold: true,
            // },
            {
              text: `DATE : ${moment().format("DD-MM-YYYY")}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
              bold: true,
            },
          ],
        },
      ],
    },
  ];

  return billingDetails;
};

const billingTable = async (
  location,
  WE_USING_PAYPHONE,
  invoice,
  bankBranch,
  Rate,
  profileData,
  weight,
  BookingData,
  builltyData,
  billingData
) => {
  const addEmptyRows = (count) =>
    Array.from({ length: count }, () =>
      Array.from({ length: 8 }, () => ({
        text: "",
        fontSize: 9,
        alignment: "center",
        border: [false, false, false, false],
      }))
    );

  const displayWeight = (
    BookingData?.actualWeight !== undefined && !isNaN(+BookingData?.actualWeight)
      ? BookingData?.actualWeight
      : "Fix"
  )?.toUpperCase() || weight?.toUpperCase();
  // Mapping if buillty data not available
  const tableBody = [
    [
        {
          text: `${moment(BookingData?.loadingDate).format("DD-MM-YYYY")}`,
          fontSize: 8,
          bold: true,
          alignment: "center",
          border: [false, false, true, false],
        },
        {
          text: `${BookingData?.companyInvoiceNo}`,
          fontSize: 8,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: BookingData?.vehicleNo,
          fontSize: 8,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: BookingData?.from,
          fontSize: 8,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: BookingData?.to,
          fontSize: 8,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: displayWeight,
          fontSize: 8,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: Rate,
          fontSize: 8,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: invoice?.total ? invoice?.total : "--",
          bold: true,
          fontSize: 8,
          alignment: "center",
          border: [true, false, false, false],
        },
      ],
      ...addEmptyRows(6),

  ]

  const tableBody1 = [
    [
      {
        text: "LOADING DATE",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [false, true, true, true],
      },
      {
        text: "INVOICE NO.",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "VEHICLE NO.",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "FROM",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "TO",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "WEIGHT",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "RATE",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "AMOUNT",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [true, true, false, true],
      },
    ],
    ...tableBody,
  ];

  const table = {
    heights: 20,
    widths: ["12%", "13%", "15%", "13%", "13%", "10%", "10%", "14%"],
    headerRows: 1,
    fontSize: 9,
    bold: true,
    margin: [0, 10, 0, 10],
    body: tableBody1,
  };

  const content = [
    // Other content
    {
      table,
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 ? 1 : 0.5; // Set to 1 for the bottom border of each cell in the first row, 0.5 for other rows
        },
        hLineColor: function (i) {
          return i === 0 ? "#000" : "#000"; // Set color for the bottom border of each cell in the first row
        },
        paddingTop: function () {
          return 4; // Adjust padding as needed
        },
        paddingBottom: function () {
          return 4; // Adjust padding as needed
        },
        vLineWidth: function (i) {
          return 1; // Set to 0 for no vertical borders
        },
        vLineColor: function () {
          return "#000"; // Set to white or your background color
        },
      },
    },
    // Other content
  ];

  return content;
};

const billingPayment = async (
  location,
  WE_USING_PAYPHONE,
  invoice,
  bankBranch,
  Rate,
  profileData,
  note,
  billingData
) => {
  const Images = {
    axisbarcode: await getBase64ImageFromURL(axisBarcode),
  };
  const billingDetails = [
    note
      ? {
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 320,
                  h: 30,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              margin: [0, 3, 10, 3],
              relativePosition: { x: 10, y: -28 },
              columns: [
                {
                  width: 40,
                  text: "NOTE:",
                  fontSize: 11,
                  bold: true,
                },
                {
                  width: 260,
                  text: `${note}`,
                  fontSize: 9,
                  bold: true,
                },
              ],
            },
          ],
        }
      : {
          text: "",
        },
    {
      table: {
        margin: [0, 0, 0, 0],
        widths: ["100%"],
        heights: [1],
        headerRows: 1,
        bold: true,
        body: [
          [
            {
              text: "",
              border: [false, true, false, false],
            },
          ],
        ],
      },
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          stack: [
            {
              margin: [0, 0, 0, 0],
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 250,
                      h: 30,
                      r: 5,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  relativePosition: { x: 70, y: -28 },
                  text: "OUR BANK DETAILS",
                  color: "#000",
                  fontSize: 11,
                  bold: true,
                },
                {
                  relativePosition: { x: 16, y: -12 },
                  text: "(YOU MAY ALSO PAY USING ANY UPI APP BY SCANNING A BARCODE)",
                  color: "#000",
                  fontSize: 7,
                },
              ],
            },
            {
              columns: [
                {
                  image: Images.axisbarcode,
                  width: 60,
                  height: 60,
                  margin: [0, 5, 2, 0],
                },
                {
                  margin: [2, 2, 0, 0],
                  stack: [
                    {
                      margin: [2, 5, 0, 0],
                      text: `BANK NAME: ${bankBranch.bank}`,
                      fontSize: 8,
                    },
                    {
                      margin: [2, 5, 0, 0],
                      text: `BRANCH: ${bankBranch.branch}`,
                      fontSize: 8,
                    },
                    {
                      margin: [2, 5, 0, 0],
                      text: `A/C. NO: ${bankBranch.accNo}`,
                      fontSize: 8,
                    },
                    {
                      margin: [2, 5, 0, 0],
                      text: `IFSC CODE: ${bankBranch.ifsc}`,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          width: "35%",
          border: [false, true, false, false],
          margin: [0, 0, 0, 0],
          stack: [
            {
              border: [false, true, false, false],
              columns: [
                {
                  width: "50%",
                  stack: [
                    {
                      text: `TOTAL:`,
                      color: "#000",
                      bold: true,
                      fontSize: 9,
                      margin: [0, 2, 0, 0],
                      alignment: "right",
                    },
                    location?.data?.data?.hamali
                      ? {
                          text: `HAMALI:`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          alignment: "right",
                        }
                      : null,
                    location?.data?.data?.detention
                      ? {
                          text: `DETENTION :`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          alignment: "right",
                        }
                      : null,
                    location?.data?.data?.extraCharge
                      ? {
                          text: `EXTRA CHARGE :`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          alignment: "right",
                        }
                      : null,
                  ],
                },
                {
                  margin: [0, 0, 10, 0],
                  alignment: "right",
                  width: "*",
                  stack: [
                    {
                      text: `₹ ${
                        invoice?.total ? invoice?.total : "--"
                      }`,
                      color: "#000",
                      bold: true,
                      fontSize: 9,
                      margin: [0, 2, 0, 0],
                      font: "NotoSansDevanagari",
                    },
                    location?.data?.data?.hamali
                      ? {
                          text: `₹ ${location?.data?.data?.hamali}`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          font: "NotoSansDevanagari",
                        }
                      : null,
                    location?.data?.data?.detention
                      ? {
                          text: `₹ ${location?.data?.data?.detention}`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          font: "NotoSansDevanagari",
                        }
                      : null,
                    location?.data?.data?.extraCharge
                      ? {
                          text: `₹ ${location?.data?.data?.extraCharge}`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          font: "NotoSansDevanagari",
                        }
                      : null,
                  ],
                },
              ],
            },
            {
              margin: [
                0,
                !location?.data?.data?.hamali &&
                !location?.data?.data?.detention &&
                !location?.data?.data?.extraCharge
                  ? 45
                  : 0,
                0,
                0,
              ],
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 180,
                  h: 25,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: 0, y: -25 },
              alignment: "center",
              columns: [
                {
                  width: 100,
                  text: "GRAND TOTAL",
                  color: "#000",
                  fontSize: 9,
                  bold: true,
                  margin: [0, 7, 0, 0],
                },
                {
                  width: 5,
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 2,
                      h: 25,
                      color: "black",
                    },
                  ],
                },
                {
                  margin: [0, 7, 10, 0],
                  width: "*",
                  text: `₹ ${invoice?.total ? invoice?.total : "--"}`,
                  color: "#000",
                  fontSize: 9,
                  font: "NotoSansDevanagari",
                  bold: true,
                  alignment: "right",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      margin: [0, 5, 0, 0],
      stack: [
        {
          text: WE_USING_PAYPHONE.toUpperCase(),
          fontSize: 9,
        },
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 0,
              w: 525, // width of the rectangle
              h: 1, // height of the rectangle
              color: "black",
            },
          ],
        },
        {
          columns: [
            {
              characterSpacing: 0.5,
              margin: [0, 5, 0, 0],
              text: "AMOUNT CHARGEABLE (IN WORDS)",
              fontSize: 11,
            },
            {
              characterSpacing: 0.5,
              margin: [0, 5, 0, 0],
              text: "as per MSME law you kindly release payment in 45 days".toUpperCase(),
              fontSize: 8,
              bold:true
            },
          ],
        },
        {
          characterSpacing: 0.8,
          margin: [0, 5, 0, 0],
          text: `${
            billingData?.totalInWords && billingData?.totalInWords.toUpperCase()
          } ONLY`,
          fontSize: 13,
        },
        {
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 2,
                  w: 525, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 1,
                  w: 525, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return billingDetails;
};

export const SimpleBookingBilling = async (
  location,
  WE_USING_PAYPHONE,
  invoice,
  bankBranch,
  Rate,
  profileData,
  ShowHeader,
  invoiceNo,
  weight,
  BookingData,
  note,
  builltyData,
  billingData
) => {
  const BillingInfo = await billingDetails(
    location,
    WE_USING_PAYPHONE,
    invoice,
    bankBranch,
    Rate,
    profileData,
    ShowHeader,
    invoiceNo,
    billingData
  );

  const BillingTabDATA = await billingTable(
    location,
    WE_USING_PAYPHONE,
    invoice,
    bankBranch,
    Rate,
    profileData,
    weight,
    BookingData,
    builltyData,
    billingData
  );

  const BillingPayData = await billingPayment(
    location,
    WE_USING_PAYPHONE,
    invoice,
    bankBranch,
    Rate,
    profileData,
    note,
    billingData
  );

  const BillingFooterContent = await BillingFooter("billing");

  return [
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
    BillingInfo,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
    BillingTabDATA,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
    {
      stack: [BillingPayData, BillingFooterContent],
      unbreakable: true,
    },
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
  ];
};

