import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Notification from "../../Components/UI/Notification";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import DatePicker from "react-datepicker";
import Checkbox from "../../Components/UI/Checkbox";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import axiosInstance from "../../Utils/API";
import { ADD_BOOKING } from "../../Utils/ApiGlobal";
import { useHistory } from "react-router-dom";
// import SearchVelicleOwner from "../../Components/Functionality/SearchVelicleOwner";
import SearchVehicleNo from "../../Components/Functionality/SearchVehicleNo";
import CompanyInformation from "../../Components/Functionality/CompanyInformation";
import moment from "moment";
// import Swal from "sweetalert2";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import APILoader from "../../Components/UI/APILoader";
import { GET_VEHICLE_OWNERBYID } from "../../Utils/ApiGlobal";
import { useLocation } from "react-router-dom";
import DynamicGroupDisclosure from "../../Components/Functionality/DynamicGroupDisclosure";
import WithoutLrNo from "./WithoutLrNo";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

const error_notification = (props) => ({
  mode: "failure",
  timeout: 5000,
  top: 10,
  title: props.title,
});

export default function AddBooking() {
  const location = useLocation();
  const childButtonRef = useRef(null);

  const itemsArray = [];
  for (let i = 1; i <= location.state.countOfLr; i++) {
    itemsArray.push({
      id: i - 1,
      title: `LR No ${parseInt(localStorage.getItem("lrValue")) + (i - 1)}`,
      content: <div>Content for item {i}</div>,
      open: false,
      lrValue: `${parseInt(localStorage.getItem("lrValue")) + (i - 1)}`,
      lrCount: location.state.countOfLr,
    });
  }

  const [notif, setNotif] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [notifOpts, setNotifOpts] = useState({});
  const [LRDate, setLRDate] = useState(new Date());
  const [balanceCal, setBalanceCal] = useState(0);
  const [totalCal, setTotalCal] = useState(0);
  // const [checked, setChecked] = useState();
  const [vehicleOwnerId, setVehicleOwnerId] = useState();
  const [vehicleOwnerName, setVehicleOwnerName] = useState();
  // const [newvehicleOwnerName, setNewVehicleOwnerName] = useState();
  const [vehicleNumber, setVehicleNumber] = useState();
  const [consignorName, setConsignorName] = useState("");
  const [consignorAdd, setConsignorAdd] = useState("");
  const [consignorBillAdd, setConsignorBillAdd] = useState("");
  const [consignorGST, setConsignorGST] = useState("");
  const [consigneeName, setConsigneeName] = useState("");
  const [consigneeAdd, setConsigneeAdd] = useState("");
  const [consigneeBillAdd, setConsigneeBillAdd] = useState("");
  const [consigneeGST, setConsigneeGST] = useState(""); // eslint-disable-next-line
  const [payTo, setPayTo] = useState(""); // eslint-disable-next-line
  const [isThirdParty, setIsThirdParty] = useState(false); // eslint-disable-next-line
  const [partyName, setPartyName] = useState(""); // eslint-disable-next-line
  const [otherPartyName, setOtherPartyName] = useState(""); // eslint-disable-next-line
  const [otherPartyAdd, setOtherPartyAdd] = useState(""); // eslint-disable-next-line
  const [otherPartyGST, setOtherPartyGST] = useState(""); // eslint-disable-next-line
  const history = useHistory();
  const [lrValue] = useState(localStorage.getItem("lrValue"));
  //Onchange input fields store data array
  const [addFormValues, setAddFormValues] = useState({
    consignorName: "",
    consignorAdd: "",
    consignorBillAdd: "",
    consignorGST: "",
    consigneeName: "",
    consigneeAdd: "",
    consigneeBillAdd: "",
    consigneeGST: "",
    from: "",
    to: "",
    LR_No: lrValue,
    companyInvoiceNo: "",
    bookingAmount: 0,
    paidBy: "",
    note: "",
    Freight: 0,
    advance: 0,
    consigneeAmount: 0,
    consignerAmount: 0,
    isThirdPartyPay: false,
    thirdPartyName: "",
    thirdPartyComment: "",
    thirdPartyCommission: 0,
    otherPartyName: "",
    otherPartyGst: "",
    otherPartyAddress: "",
  });
  const [lrData, setLrData] = useState([]);
  // Select Vehicle Owner
  // const handleOnChange = (e) => {
  //   setVehicleOwnerId(e.value);
  //   setVehicleOwnerName(e.label);
  // };

  // Select handle Vehicle Number
  const handleVehicleOnChange = (e) => {
    // console.log(e.label)
    // console.log(e.value)
    // console.log(e.ownerId)
    setVehicleNumber(e.label);
    setVehicleOwnerId(e.ownerId);
    // setNewVehicleOwnerName(e.vehicleOwner);
  };

  // Get Vehicle Owner Name By ID
  useEffect(() => {
    axiosInstance
      .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${vehicleOwnerId}`)
      .then((response) => {
        // console.log(response)
        // console.log(response?.data?.data[0].ownerName)
        setVehicleOwnerName(response?.data?.data[0]?.ownerName);
      });
  }, [vehicleOwnerId]);

  // Select handle company
  const handleOnChangeCompany = (e) => {
    if (e.type.name === "cosignor") {
      setConsignorName(e.label);
      setConsignorAdd(e.add);
      setConsignorGST(e.value);
      setConsignorBillAdd(e.billAdd);
    } else if (e.type.name === "consignee") {
      setConsigneeName(e.label);
      setConsigneeAdd(e.add);
      setConsigneeGST(e.value);
      setConsigneeBillAdd(e.billAdd);
    } else if (e.type.name === "OtherParty") {
      setOtherPartyName(e.label);
      setOtherPartyAdd(e.add);
      setOtherPartyGST(e.value);
    }
  };

  //Onchange values handleClick event
  const values_handler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newValue;
    if (typeof value === "string" && value.trim().length === 0) {
      newValue = 0;
    } else {
      newValue = value;
    }
    const newValues = {
      ...addFormValues,
      [name]: newValue,
    };
    setAddFormValues(newValues);
    if (name === "Freight" || name === "advance") {
      calTotal(newValues);
    }
  };

  // Total Calculation (Freight - Advance)
  const calTotal = (addFormValues) => {
    const { Freight, advance } = addFormValues;
    const balance = parseInt(Freight) - parseInt(advance);
    setBalanceCal(parseInt(balance));
    setTotalCal(balance);
  };

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });

  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  const AddBooking = (ev) => {
    ev.preventDefault();

    if (childButtonRef.current) {
      // If the ref exists, trigger a click event on the child button
      childButtonRef.current.click();
    }

    // setTimeout(() => {
    //   console.log(lrData,"lrdata")
    // }, 1000);
    if (!vehicleNumber) {
      setFormErrors({ vehicleNumber: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Number` })
      );
      return;
    } else if (consignorName === "") {
      setFormErrors({ consignorName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter Consigner Name` })
      );
      return;
    } else if (consigneeName === "") {
      setFormErrors({ consigneeName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please select Consignee Name` })
      );
      return;
    }
    // eslint-disable-next-line
    var obj = formValueConvertor(getFormJSON(ev.target));
    
    let ADD_BOOKING_DATA = {
      LoadingDate: moment(LRDate).toDate(),
      VehicleNo: vehicleNumber.toUpperCase(),
      VehicleOwner: vehicleOwnerName,
      ConsignerName: consignorName.toUpperCase(),
      ConsignerAddress: consignorAdd.toUpperCase(),
      ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
      ConsignerGst: consignorGST.toUpperCase(),
      ConsigneeName: consigneeName.toUpperCase(),
      ConsigneeAddress: consigneeAdd.toUpperCase(),
      ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
      ConsigneeGst: consigneeGST.toUpperCase(),
      CompanyInvoiceNo: addFormValues.companyInvoiceNo,
      IsThirdPartyPay: addFormValues.isThirdPartyPay,
      OtherPartyName: addFormValues.otherPartyName,
      OtherPartyAddress: addFormValues.otherPartyAddress,
      OtherPartyGst: addFormValues.otherPartyGst,
      ThirdPartyName: addFormValues.thirdPartyName,
      ThirdPartyCommission: addFormValues.thirdPartyCommission,
      thirdPartyComment: addFormValues.thirdPartyComment,
      PaidBy: addFormValues.paidBy,
      ConsignerAmount:addFormValues.consignerAmount,
      ConsigneeAmount: addFormValues.consigneeAmount,
      BookingAmount: addFormValues.bookingAmount,
      // LrDetails: JSON.parse(localStorage.getItem("lrDetails")) || [],
      Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
      Advance: parseInt(addFormValues.advance), //Data accepts INT format
      Balance: parseInt(balanceCal), //Data accepts INT format
      Total: totalCal,
      From: addFormValues.from,
      To: addFormValues.to,
      LrDetails: [],
      CreatedBy: localStorage.getItem("userId"),
      IsBiltyReceive: false,
      billtyReceiveDate: "0001-01-01",
      IsBlankBilltyAvailable: false,
      TotalBlankBillty: 0,
      FinancialYear: addFormValues.FinancialYear,
    };
    setLoader({ ...loader, loading: true });

    axiosInstance
      .post(ADD_BOOKING, ADD_BOOKING_DATA)
      .then((response) => {
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/pending-booking",
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
    // Swal.fire({
    //   icon: "info",
    //   title: "Are you sure you want to create a new booking without buillty?",
    //   showCancelButton: true,
    //   cancelButtonColor: "rgb(148 163 184)",
    //   confirmButtonColor: "#ed1c24",
    //   confirmButtonText: "Yes",
    //   cancelButtonText: "No",
    // }).then((result) => {
    //   /* Read more about isConfirmed, isDenied below */
    //   if (result.isConfirmed) {
    //     setLoader({ ...loader, loading: true });
    //     let ADD_BOOKING_DATA = {
    //       LoadingDate: moment(LRDate).toDate(),
    //       VehicleNo: vehicleNumber.toUpperCase(),
    //       VehicleOwner: vehicleOwnerName,
    //       ConsignerName: consignorName.toUpperCase(),
    //       ConsignerAddress: consignorAdd.toUpperCase(),
    //       ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
    //       ConsignerGst: consignorGST.toUpperCase(),
    //       ConsigneeName: consigneeName.toUpperCase(),
    //       ConsigneeAddress: consigneeAdd.toUpperCase(),
    //       ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
    //       ConsigneeGst: consigneeGST.toUpperCase(),
    //       CompanyInvoiceNo: addFormValues.companyInvoiceNo,
    //       OtherPartyName: addFormValues.otherPartyName,
    //       OtherPartyAddress: addFormValues.otherPartyAddress,
    //       OtherPartyGst: addFormValues.otherPartyGst,
    //       ThirdPartyName: addFormValues.thirdPartyName,
    //       ThirdPartyCommission: addFormValues.thirdPartyCommission,
    //       thirdPartyComment: addFormValues.thirdPartyComment,
    //       PaidBy: addFormValues.paidBy,
    //       ConsignerAmount:addFormValues.consignerAmount,
    //       ConsigneeAmount: addFormValues.consigneeAmount,
    //       BookingAmount: addFormValues.bookingAmount,
    //       // LrDetails: JSON.parse(localStorage.getItem("lrDetails")) || [],
    //       Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
    //       Advance: parseInt(addFormValues.advance), //Data accepts INT format
    //       Balance: parseInt(balanceCal), //Data accepts INT format
    //       Total: totalCal,
    //       From: addFormValues.from,
    //       To: addFormValues.to,
    //       LrDetails: [],
    //       CreatedBy: localStorage.getItem("userId"),
    //       IsBiltyReceive: false,
    //       billtyReceiveDate: "0001-01-01",
    //       IsBlankBilltyAvailable: false,
    //       TotalBlankBillty: 0,
    //       FinancialYear: addFormValues.FinancialYear,
    //     };
    //     axiosInstance
    //       .post(ADD_BOOKING, ADD_BOOKING_DATA)
    //       .then((response) => {
    //         ConfirormationModal(response.data.data.message, "success", 2000);
    //         history.push({
    //           pathname: "/pending-booking",
    //         });
    //       })
    //       .catch((error) => {
    //         ConfirormationModal(error.message, "error", 2000);
    //       });
    //   }
    // });
  };
  // Builty checkbox click
  const builltyClick = (ev, path) => {
    // console.log(addFormValues);
    ev.preventDefault();
    if (childButtonRef.current) {
      // If the ref exists, trigger a click event on the child button
      childButtonRef.current.click();
    }
    if (!vehicleNumber) {
      setFormErrors({ vehicleNumber: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Number` })
      );
      return;
    } else if (consignorName === "") {
      setFormErrors({ consignorName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter Consigner Name` })
      );
      return;
    } else if (consigneeName === "") {
      setFormErrors({ consigneeName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please select Consignee Name` })
      );
      return;
    } else if (!addFormValues.FinancialYear) {
      setFormErrors({ FinancialYear: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please Enter FinancialYear` }));
      return;
    }
    // else if (addFormValues.LR_No === "") {
    //   setFormErrors({ LR_No: true });
    //   setNotif(true);
    //   setNotifOpts(error_notification({ title: `Please select LR No` }));
    //   return;
    // }
    // Swal.fire({
    //   icon: "info",
    //   title: "Do you want to create blank buillty?",
    //   showCancelButton: true,
    //   cancelButtonColor: "rgb(148 163 184)",
    //   confirmButtonColor: "#ed1c24",
    //   confirmButtonText: "Yes",
    //   cancelButtonText: "No",
    // })

    if (ev.target.checked === true) {
      setLoader({ ...loader, loading: true });

      const lrData = JSON.parse(localStorage.getItem("lrDetails"));
      let ADD_BOOKING_DATA = {
        LoadingDate: moment(LRDate).toDate(),
        VehicleNo: vehicleNumber.toUpperCase(),
        VehicleOwner: vehicleOwnerName,
        ConsignerName: consignorName.toUpperCase(),
        ConsignerAddress: consignorAdd.toUpperCase(),
        ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
        ConsignerGst: consignorGST.toUpperCase(),
        ConsigneeName: consigneeName.toUpperCase(),
        ConsigneeAddress: consigneeAdd.toUpperCase(),
        ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
        ConsigneeGst: consigneeGST.toUpperCase(),
        From: lrData?.length === 1 ? lrData[0].From : null,
        To: lrData?.length === 1 ? lrData[0].To : null,
        // PaidBy: payTo,
        // ConsignerAmount: addFormValues.ConsignorAmount,
        // ConsigneeAmount: addFormValues.ConsigneeAmount,
        // New features
        // IsThirdPartyPay: isThirdParty,
        // ThirdPartyName: isThirdParty === true ? partyName : null,
        // ThirdPartyCommission:
        //   isThirdParty === true ? addFormValues.ThirdPartyCommission : null,
        // ThirdPartyComment:
        //   isThirdParty === true ? addFormValues.ThirdPartyComment : null,

        // Other new feature
        // otherPartyName: otherPartyName.toUpperCase(),
        // otherPartyGst: otherPartyGST.toUpperCase(),
        // otherPartyAddress: otherPartyAdd.toUpperCase(),

        // Other
        // otherPartyName: addFormValues?.otherPartyName,
        // otherPartyGst: addFormValues?.otherPartyGst,
        // otherPartyAddress: addFormValues?.otherPartyAddress,
        // FinancialYear
        FinancialYear: addFormValues.FinancialYear,
        CompanyInvoiceNo: addFormValues.companyInvoiceNo,
        BookingAmount: parseInt(addFormValues.bookingAmount), //Data accepts INT format
        Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
        Advance: parseInt(addFormValues.advance), //Data accepts INT format
        Balance: parseInt(balanceCal), //Data accepts INT format
        Total: totalCal,
        CreatedBy: localStorage.getItem("userId"),
        IsBiltyReceive: false,
        LrDetails: JSON.parse(localStorage.getItem("lrDetails")),
        billtyReceiveDate: "0001-01-01",
        IsBlankBilltyAvailable: false,
        TotalBlankBillty: 0,
      };
      // console.log(ADD_BOOKING_DATA)
      axiosInstance
        .post(ADD_BOOKING, ADD_BOOKING_DATA)
        .then((response) => {
          // console.log(response.data)
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: path,
            state: {
              bookingId: response.data.data.bookingId,
            },
          });
        })
        .catch((error) => {
          ConfirormationModal(error.message, "error", 2000);
        });
    }
  };
  // Create blank buillty
  // const createBlanckBuillty = () => {
  //   // /bilty/billty-invoice-view
  //   let path = "/bilty/blank-buillty";
  //   history.push({
  //     pathname: path,
  //   });
  // };

  return (
    <>
      <DashBoardLayout pageTitle="Add Booking">
        <APILoader loader={loader} />
        <Notification
          show={notif}
          setShow={setNotif}
          {...notifOpts}
          supportContent={true}
        ></Notification>
        {/* {optionsComapny.length > 0 &&  */}
        <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
          <form onSubmit={AddBooking}>
            <div className="">
              <div className=" text-left space-y-6">
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="L. Date" isRequired="true" />
                    <div className="flex flex-row justify-center ">
                      {/* loadingDate */}
                      <DatePicker
                        dateFormat="dd-MM-yyyy"
                        className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                        selected={LRDate}
                        onChange={(date) => setLRDate(date)}
                        name="L_date"
                      />
                    </div>
                  </div>
                  {/* Vehicle No. */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle No." isRequired="true" />
                    <SearchVehicleNo
                      defaultValue={vehicleNumber}
                      // vehicleOwnerId={vehicleOwnerId}
                      handleOnChange={handleVehicleOnChange}
                    />
                    {formErrors.vehicleNumber === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter vehicle Number
                      </p>
                    )}
                  </div>
                  {/* Vehicle Owner Name. */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle Owner" />
                    {/* <SearchVelicleOwner
                      newvehicleOwnerName={newvehicleOwnerName}
                      defaultValue={vehicleOwnerName}
                      handleOnChange={handleOnChange}
                    /> */}
                    <Input
                      name="vehicleOwnerName"
                      defaultValue={vehicleOwnerName}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/* Consignor's Name */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's Name" isRequired="true" />
                    <CompanyInformation
                      name="cosignor"
                      defaultValue=""
                      handleOnChange={handleOnChangeCompany}
                    />
                    {formErrors.consignorName === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please select Consignor Name
                      </p>
                    )}
                  </div>
                  {/* Consignor's Address */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's Address" isRequired="true" />
                    <Input
                      name="Consignor_Address"
                      dyn
                      type="text"
                      defaultValue={consignorAdd}
                      onChange={values_handler}
                    />
                  </div>
                  {/*Consignor's GST No.  */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's GST No." />
                    <Input
                      name="Consignor_GST_No"
                      type="text"
                      defaultValue={consignorGST}
                      onChange={values_handler}
                    />
                  </div>
                </div>

                <div className="flex  md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/*  Consignee's Name */}
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Consignee's Name" isRequired="true" />
                    <CompanyInformation
                      name="consignee"
                      defaultValue=""
                      handleOnChange={handleOnChangeCompany}
                    />
                    {formErrors.consigneeName === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please select Consignee Name
                      </p>
                    )}
                  </div>
                  {/* Consignee's Address */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's Address" isRequired="true" />
                    <Input
                      name="Consignee_Address"
                      defaultValue={consigneeAdd}
                      onChange={values_handler}
                    />
                  </div>
                  {/* Consignee's GST No. */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's GST No." />
                    <Input
                      name="Consignee_GST_No"
                      type="text"
                      defaultValue={consigneeGST}
                      onChange={values_handler}
                    />
                  </div>
                </div>
                {/* Finacial Year */}
                <div className="flex  md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Financial Year" isRequired="true" />
                    <Input
                      name="FinancialYear"
                      type="text"
                      onChange={values_handler}
                    />
                    {formErrors.FinancialYear === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter Financial Year
                      </p>
                    )}
                  </div>
                  {/* {itemsArray?.length === 0 && (
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="From" isRequired="true" />
                      <Input
                        name="From"
                        type="text"
                        onChange={values_handler}
                      />
                      {formErrors.From === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter From
                        </p>
                      )}
                    </div>
                  )} */}
                  {/* {itemsArray?.length === 0 && (
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="To" isRequired="true" />
                      <Input name="To" type="text" onChange={values_handler} />
                      {formErrors.To === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter To
                        </p>
                      )}
                    </div>
                  )} */}
                </div>
                {/* {itemsArray?.length === 0 && (
                  <div className=" flex   flex-col  text-left w-40">
                    <Label label="Company Invoice No." isRequired="true" />
                    <Input
                      name="Bill_No"
                      type="text"
                      onChange={values_handler}
                    />
                    {formErrors.CompanyInvoiceNo === true && (
                      <p className="text-red-500 font-bold text-left text-xs">
                        Please enter Company Invoice Number
                      </p>
                    )}
                  </div>
                )} */}
                {itemsArray?.length === 0 && (
                  <WithoutLrNo
                    addFormValues={addFormValues}
                    setAddFormValues={setAddFormValues}
                    values_handler={values_handler}
                  />
                )}

                <DynamicGroupDisclosure
                  items={itemsArray}
                  setLrData={setLrData}
                  lrData={lrData}
                  childButtonRef={childButtonRef}
                  // ref={childRef}
                />

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/*Freight  */}
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Vehicle Freight" />
                    <Input
                      type="text"
                      name="Freight"
                      // placeholder="Default value 0"
                      onChange={values_handler}
                    />
                  </div>
                  {/* Advance */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle Advance" />
                    <Input
                      type="text"
                      name="advance"
                      // placeholder="Default value 0"
                      onChange={values_handler}
                    />
                  </div>
                  {/* Balance */}
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Vehicle Balance" isRequired="true" />
                    <p
                      className="
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-gray-100 bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      {balanceCal}
                    </p>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/* Builty checkbox */}
                  {/* <div className=" flex   flex-col pt-2  text-left">
                    <Label label="Buillty" />
                    <Checkbox
                      className="accent-red-600	text-white"
                      onClick={(ev) => {
                        builltyClick(ev, "/bilty/add-builty");
                      }}
                    />
                  </div> */}
                  {/* Builty checkbox */}
                  {itemsArray?.length !== 0 && (
                    <div className=" flex  flex-col pt-2  text-left">
                      <Label label="Add Buillty" />
                      <Checkbox
                        onClick={(ev) => {
                          builltyClick(ev, "/bilty/add-default-buillty");
                        }}
                        className="accent-red-600	text-white"
                      />
                    </div>
                  )}

                  {/* Builty checkbox */}
                  {/* <div className=" flex  flex-col pt-2  text-left">
                    <Label label="Blank Buillty" />
                    <Checkbox
                      onClick={(ev) => {
                        createBlanckBuillty(ev);
                      }}
                      className="accent-red-600	text-white"
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="items-center mt-4">
              <ButtonWithBG
                theme="primary"
                label="SAVE"
                type="submit"
                disabled={location.state.countOfLr > 0 ? true : false}
              />
            </div>
          </form>
        </div>
        {/* } */}
      </DashBoardLayout>
    </>
  );
}
