import React from 'react'

const Checkbox = ({ handleClick, ...rest }) => {
    return (
        <input
            type="checkbox"
            onClick={handleClick}
            className="cursor-pointer h-4 w-4 inline-block text-red-kirtiroadways accent-red-600 focus:ring-red-kirtiroadways border-gray-300 rounded"
            {...rest}
        />
    )
}

export default Checkbox
